/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* @import url("https://fonts.googleapis.com/css?family=Josefin+Sans"); */

@font-face {
  font-family: KOJ;
  src: url(../src/fonts/KnockoutHTF30JuniorWelterwt.otf);
}

@font-face {
  font-family: KOW;
  src: url(../src/fonts/KnockoutHTF50Welterweight.otf);
}

@font-face {
  font-family: Leviathan;
  src: url(../src/fonts/Leviathan-HTF-Black-Italic.otf);
}

body,
html,
#root {
  flex-grow: 1;
  width: 100%;
  /* scroll-behavior: smooth; */
  /* overscroll-behavior: none; */
}

a {
  text-decoration: none;
  color: inherit;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(transparent, #3fa9f5);
}
