@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");

/* :root{
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
} */
.newlink{
  
  color:white;
  background-color: transparent;
  text-decoration: underline;
  font:inherit;
}

 
.newlink:hover {
  color:deepskyblue;
  background-color: transparent;
  text-decoration: underline;
}

.forgotPassword {
  margin-top:30px;
  text-align:right;
 }
 .forgotPassword:hover {
  text-decoration:underline;
  color: red;
 }

 /* Banner Section on all pages */
.welcome {
  color: white;
  display: flex;
  padding-left: 4rem;
  min-height: 800px;
  background-size: cover;
  background-image: url(images/Pupper.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Large Screens */
/* iPad Custom Landscape */
@media only screen and (max-width: 1024px) {
  .welcome {
    min-height: 650px;
  }
}

/* iPad Custom Portrait*/
@media only screen and (max-width: 768px) and (max-height: 1024px) {
  .welcome {
    min-height: 600px;
  }
}

/* General Breaks/Responsivness */
@media only screen and (max-width: 768px) and (max-height: 768px) {
  .welcome {
    min-height: 400px;
  }
}

/* General Breaks/Responsivness */
@media only screen and (max-width: 740px) and (max-height: 740px){
  .welcome {
    min-height: 400px;
  }
}

/* Samsung S9 Landscape */
@media only screen and (max-width: 740px) and (max-height: 360px){
  .welcome {
    min-height: 290px;
  }
}

/* iPhone 6/7/8 Plus Portrait*/ 
@media only screen and (max-width: 414px) {
  .welcome {
    min-height: 400px;
  }
}

/* iPhone 6/7/8 Plus Landscape*/ 
@media only screen and (max-width: 736px) and (max-height: 414px) {
  .welcome {
    min-height: 350px;
  }
}

/* 
iPhone 6/7/8 Portrait*/
@media only screen and (max-width: 375px) {
  .welcome {
    min-height: 375px;
  }
}

/* iPhone 6/7/8 Landscape*/
@media only screen and (max-width: 667px) and (max-height: 375px){
  .welcome {
    min-height: 300px;
  }
}

.Photocontent {
  padding: 80px 0px 200px 0px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 800px;
}
/* base styles & title */
/* body{
  font-family: "Noto Serif";
  color: var(--secondary);
  background-color: whitesmoke;
} */
.PhotoApp {
  max-width: 960px;
  margin: 0 auto;
}

/* upload form styles */
form {
  margin: 30px auto 10px;
  text-align: center;
}

.photolabel input {
  height: 0;
  width: 0;
  opacity: 0;
}
.photolabel {
  display: block;
  height: 30px;
  width:30px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  line-height: 30px;
  color: whitesmoke;
  font-weight: bold;
  font-size: relative;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.photolabel:hover {
  background: rgb(124, 124, 124);
  color: rgba(255, 255, 255, 0.37);
}
.upload {
  font-family:KOJ;
  font-size: 22px;
  color: whitesmoke;
  margin: 10px 0;
  
}

/* image grid styles */
.img-grid {
  margin: 20px 6%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 40px;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.9;
  color: rgba(245, 245, 245, 0.904);
  border: 2px solid rgba(245, 245, 245, 0.76);
  border-radius: 2%;
}
.img-wrap:hover{
  background: rgba(0, 0, 0, 2);
   
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 105%;
  max-height: 105%;
  position: absolute;
  top: 0;
  left: 0;
  
}
 .black {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1em 1.2em;
  height: 4em;
  background-color: rgba(0, 0, 0, .65);
  -webkit-transition: height 0.8s, padding 0.8s ;
  transition: height 0.8s, padding 0.8s ;
 
 }
  .black:hover {
    background-color: rgba(0, 0, 0, .9);
    height:100%;
    padding-top: 50%;  
  }

.black a {
  color: white;
  font-size: 22px;
  text-decoration: none;
  outline: none;
  font-family:KOW, sans-serif;
}


.or {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 20px 0 10px;
  font-weight: lighter;
  font-family: inherit; 
} 

.or span { 
   background:#fff; 
   padding:0 10px; 
  }

  .MuiDialogTitle-root{
    font-family:Verdana;
    text-shadow:none;
  }

.delete {
  margin:1% 0%;
  display: inline-block;
  border-radius: 4%;
  background-color: rgba(161, 9, 9, 0.8);
  color: rgba(255, 255, 255, 0.85);
  width: 10%;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.8;
  border: transparent;
  transition: opacity .3s;
}

.delete:hover {
  opacity: 1;
}

/* iPad */
/* @media only screen and (max-width: 1024px) and (max-height: 768px) {
  .delete {
    margin-right: 26%;
  }
} */

/* iPhone X/XS iOS12 Landscape*/
/* @media only screen and (max-width: 812px) and (max-height: 375px) {
  .delete {
    margin-right: 32%;
  }
} */


/* iPhone X/XS iOS12 Portrait*/
/* @media only screen and (max-width: 375px) and (max-height: 812px) {
  .delete {
    margin-right: 47%;
  }
} */

/* iPhone 6/7/8 Landscape*/
/* @media only screen and (max-width: 736px) and (max-height: 414px) {
  .delete {
    margin-right: 48%;
  }
} */

/* iPhone 6/7/8 Portrait*/
/* @media only screen and (max-width: 414px) and (max-height: 736px) {
  .delete {
    margin-right: 48%;
  }
} */

/* Samsung S9/S9+ Landscape*/
/* @media only screen and (max-width: 740px) and (max-height: 360px) {
  .delete {
    margin-right: 34%;
  }
} */

/* Samsung S9/S9+ Portrait*/
/* @media only screen and (max-width: 360px) and (max-height: 740px) {
  .delete {
    margin-right: 50%;
  }
} */


.close {
  display: inline-block;
  background-color: rgb(81, 81, 88);
  margin:1% 0%;
  border-radius: 4%;
  color: rgba(255, 255, 255, 0.85);
  width: 10%;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.8;
  border: transparent;
  transition: opacity .3s;
}

.close:hover {
  opacity: 1;
}

/* @media(orientation: portrait) {
  
.close{
  margin: 0px auto 7%;
};
.delete{
  margin: 0px auto 8%;
};
}; */

.preloader {
background-color: rgba(255, 255, 255);
position: relative;
height: 100vh;
width:100vw;
}

.preloader img {
  width:100%;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

}

 @media(orientation: portrait) {
    .welcome{
    background-size: 100% 65%;
    background-position:unset;
      
  };
 
  };