@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* @import url("https://fonts.googleapis.com/css?family=Josefin+Sans"); */

@font-face {
  font-family: KOJ;
  src: url(/static/media/KnockoutHTF30JuniorWelterwt.b782af58.otf);
}

@font-face {
  font-family: KOW;
  src: url(/static/media/KnockoutHTF50Welterweight.e0b641a1.otf);
}

@font-face {
  font-family: Leviathan;
  src: url(/static/media/Leviathan-HTF-Black-Italic.ed7a107e.otf);
}

body,
html,
#root {
  flex-grow: 1;
  width: 100%;
  /* scroll-behavior: smooth; */
  /* overscroll-behavior: none; */
}

a {
  text-decoration: none;
  color: inherit;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(transparent, #3fa9f5);
}

/* :root{
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
} */
.newlink{
  
  color:white;
  background-color: transparent;
  text-decoration: underline;
  font:inherit;
}

 
.newlink:hover {
  color:deepskyblue;
  background-color: transparent;
  text-decoration: underline;
}

.forgotPassword {
  margin-top:30px;
  text-align:right;
 }
 .forgotPassword:hover {
  text-decoration:underline;
  color: red;
 }

 /* Banner Section on all pages */
.welcome {
  color: white;
  display: flex;
  padding-left: 4rem;
  min-height: 800px;
  background-size: cover;
  background-image: url(/static/media/Pupper.2c913599.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Large Screens */
/* iPad Custom Landscape */
@media only screen and (max-width: 1024px) {
  .welcome {
    min-height: 650px;
  }
}

/* iPad Custom Portrait*/
@media only screen and (max-width: 768px) and (max-height: 1024px) {
  .welcome {
    min-height: 600px;
  }
}

/* General Breaks/Responsivness */
@media only screen and (max-width: 768px) and (max-height: 768px) {
  .welcome {
    min-height: 400px;
  }
}

/* General Breaks/Responsivness */
@media only screen and (max-width: 740px) and (max-height: 740px){
  .welcome {
    min-height: 400px;
  }
}

/* Samsung S9 Landscape */
@media only screen and (max-width: 740px) and (max-height: 360px){
  .welcome {
    min-height: 290px;
  }
}

/* iPhone 6/7/8 Plus Portrait*/ 
@media only screen and (max-width: 414px) {
  .welcome {
    min-height: 400px;
  }
}

/* iPhone 6/7/8 Plus Landscape*/ 
@media only screen and (max-width: 736px) and (max-height: 414px) {
  .welcome {
    min-height: 350px;
  }
}

/* 
iPhone 6/7/8 Portrait*/
@media only screen and (max-width: 375px) {
  .welcome {
    min-height: 375px;
  }
}

/* iPhone 6/7/8 Landscape*/
@media only screen and (max-width: 667px) and (max-height: 375px){
  .welcome {
    min-height: 300px;
  }
}

.Photocontent {
  padding: 80px 0px 200px 0px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 800px;
}
/* base styles & title */
/* body{
  font-family: "Noto Serif";
  color: var(--secondary);
  background-color: whitesmoke;
} */
.PhotoApp {
  max-width: 960px;
  margin: 0 auto;
}

/* upload form styles */
form {
  margin: 30px auto 10px;
  text-align: center;
}

.photolabel input {
  height: 0;
  width: 0;
  opacity: 0;
}
.photolabel {
  display: block;
  height: 30px;
  width:30px;
  border: 1px solid whitesmoke;
  border-radius: 50%;
  line-height: 30px;
  color: whitesmoke;
  font-weight: bold;
  font-size: relative;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.photolabel:hover {
  background: rgb(124, 124, 124);
  color: rgba(255, 255, 255, 0.37);
}
.upload {
  font-family:KOJ;
  font-size: 22px;
  color: whitesmoke;
  margin: 10px 0;
  
}

/* image grid styles */
.img-grid {
  margin: 20px 6%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 40px;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.9;
  color: rgba(245, 245, 245, 0.904);
  border: 2px solid rgba(245, 245, 245, 0.76);
  border-radius: 2%;
}
.img-wrap:hover{
  background: rgba(0, 0, 0, 2);
   
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 105%;
  max-height: 105%;
  position: absolute;
  top: 0;
  left: 0;
  
}
 .black {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1em 1.2em;
  height: 4em;
  background-color: rgba(0, 0, 0, .65);
  transition: height 0.8s, padding 0.8s ;
 
 }
  .black:hover {
    background-color: rgba(0, 0, 0, .9);
    height:100%;
    padding-top: 50%;  
  }

.black a {
  color: white;
  font-size: 22px;
  text-decoration: none;
  outline: none;
  font-family:KOW, sans-serif;
}


.or {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 20px 0 10px;
  font-weight: lighter;
  font-family: inherit; 
} 

.or span { 
   background:#fff; 
   padding:0 10px; 
  }

  .MuiDialogTitle-root{
    font-family:Verdana;
    text-shadow:none;
  }

.delete {
  margin:1% 0%;
  display: inline-block;
  border-radius: 4%;
  background-color: rgba(161, 9, 9, 0.8);
  color: rgba(255, 255, 255, 0.85);
  width: 10%;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.8;
  border: transparent;
  transition: opacity .3s;
}

.delete:hover {
  opacity: 1;
}

/* iPad */
/* @media only screen and (max-width: 1024px) and (max-height: 768px) {
  .delete {
    margin-right: 26%;
  }
} */

/* iPhone X/XS iOS12 Landscape*/
/* @media only screen and (max-width: 812px) and (max-height: 375px) {
  .delete {
    margin-right: 32%;
  }
} */


/* iPhone X/XS iOS12 Portrait*/
/* @media only screen and (max-width: 375px) and (max-height: 812px) {
  .delete {
    margin-right: 47%;
  }
} */

/* iPhone 6/7/8 Landscape*/
/* @media only screen and (max-width: 736px) and (max-height: 414px) {
  .delete {
    margin-right: 48%;
  }
} */

/* iPhone 6/7/8 Portrait*/
/* @media only screen and (max-width: 414px) and (max-height: 736px) {
  .delete {
    margin-right: 48%;
  }
} */

/* Samsung S9/S9+ Landscape*/
/* @media only screen and (max-width: 740px) and (max-height: 360px) {
  .delete {
    margin-right: 34%;
  }
} */

/* Samsung S9/S9+ Portrait*/
/* @media only screen and (max-width: 360px) and (max-height: 740px) {
  .delete {
    margin-right: 50%;
  }
} */


.close {
  display: inline-block;
  background-color: rgb(81, 81, 88);
  margin:1% 0%;
  border-radius: 4%;
  color: rgba(255, 255, 255, 0.85);
  width: 10%;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.8;
  border: transparent;
  transition: opacity .3s;
}

.close:hover {
  opacity: 1;
}

/* @media(orientation: portrait) {
  
.close{
  margin: 0px auto 7%;
};
.delete{
  margin: 0px auto 8%;
};
}; */

.preloader {
background-color: rgba(255, 255, 255);
position: relative;
height: 100vh;
width:100vw;
}

.preloader img {
  width:100%;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

}

 @media(orientation: portrait) {
    .welcome{
    background-size: 100% 65%;
    background-position:unset;
      
  };
 
  };
body,
html {
  /* height: 100vh;
  width: 100vw; */
  padding-right: 0px !important;
  /* padding-bottom: 0px !important; */
  /* overflow: visible !important; */
  /* overflow: hidden; */
  overflow-x: hidden;
}

.gridStyle {
  padding: 0px !important;
}

.emptyInvisibleDiv {
  height: 200px;
}

.containMusicPlayer {
  position: fixed;
  z-index: 10000000000;
  margin: 0px;
  padding: 0px;
  left: 0px;
  bottom: 10px;
  width: 100%;
  /* height: 20px; */
  border-radius: 0px !important;
  box-shadow: none;
}

.musicSlider {
  /* width: auto !important; */
  position: relative;
  margin-left: 20px;
  margin-top: 6px;
  width: 40%;
  min-width: 100px;
  margin-right: auto;
  /* position: relative;
  top: 0px;
  left: 20px; */
  /* float: left; */
}

.musicImageContainer {
  position: relative;
  margin-left: auto;
  width: 100%;
}

.musicImage {
  position: relative;
  margin-left: auto;
  width: 100%;
  cursor: pointer;
  max-width: 700px;
}

body::-webkit-scrollbar-track-piece:start {
  background: transparent !important;
}

body::-webkit-scrollbar-track-piece:end {
  background: transparent !important;
}

body::-webkit-scrollbar {
  width: 7px !important;
  height: 0px !important;
  display: unset;
}

body::-webkit-scrollbar-thumb {
  /* background-color: rgba(0, 0, 0, 0.4) !important; */
  position: fixed !important;
  border-radius: 10rem !important;
  z-index: 10000 !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  left: 0px !important;

  /* border: 1px solid #fff !important; */
}

.ui.inverted.menu {
  background: #5e707a !important;
}

.ui.header {
  font-size: 4.5rem !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  padding-bottom: 3rem !important;
}

.ui.label {
  background-color: white !important;
}

h3.ui.center.aligned.header.display-3.text-center.pb-5 {
  font-size: 42px !important;
  padding-bottom: 10px !important;
  padding-top: 30px !important;
  font-family: leviathan;

  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

button.ui.icon.button.videoAddButton {
  position: absolute !important;
  display: inherit !important;
  right: 0px;
  margin-top: 184px;
  background: transparent !important;
}

i.heart.outline.icon {
  color: white !important;
}

i.heart.outline.icon:hover {
  color: red !important;
}

i.heart.icon {
  color: red !important;
}

i.trash.alternate.outline.icon {
  color: white !important;
}

i.trash.alternate.outline.icon:hover {
  color: red !important;
}

.ui.inverted.segment {
  background: black;
}

.saveToButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.specificButtons {
  border-radius: 0 0.28571429rem 0.28571429rem 0 !important;
}

div.column {
  margin: 10px 10px !important;
}

div.two.column.row {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: auto !important;
  margin-left: 12% !important;
}

button.ui.icon.left.labeled.button.leftUiButton {
  padding: 11px 0px 11px 40px !important;
}

button.ui.icon.right.labeled.button.rightUiButton {
  padding: 11px 40px 11px 0px !important;
}

.ui.segment {
  border: none !important;
  padding: 0px !important;
  margin: 0px 0 10px 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px !important;
}

.ui.segment.pushable {
  min-height: 100vh;
}

/* Texfield style */
.rootText {
  display: flex;
  flex-wrap: wrap;
}
.marginText {
  margin: 1px;
}
.withoutLabelText {
  margin-top: 3px;
}
.textFieldText {
  margin: 0px !important;
  padding: 0px !important;
  width: 562px;
  height: 5ch !important;
}

.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.MuiOutlinedInput-root.Mui-focused .videoSearch {
  color: rgba(0, 0, 0, 0.5) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.MuiOutlinedInput-root:hover .videoSearch {
  color: rgba(0, 0, 0, 0.5) !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px 10px 10px 10px !important;
}

.MuiInputLabel-animated.MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
  transform-origin: top left !important;
}

.videoSearch {
  color: rgba(0, 0, 0, 0.2);
  right: -20px;
}
.videoSearch:hover {
  color: rgba(0, 0, 0, 0.5) !important;
}

.manueOpen {
  color: rgba(0, 0, 0, 0.8);
  padding: 5px 5px 5px 5px !important;
  font-size: 40px !important;
}
.manueOpenButton {
  color: rgba(0, 0, 0, 0.8);
  top: -5px;
  right: 5px;
  padding: 5px 5px 5px 5px !important;
}

button:focus {
  outline: 0px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.5) !important;
}
.MuiCardMedia-root.videocardmedia {
  position: relative;
  height: auto !important;
  width: auto !important;
}

.videocard {
  position: relative !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  display: inline-block;
}

.videoTitle {
  padding: 30px 10px 10px 0px !important;
}
.videoLike {
  padding: 10px !important;
  height: 30px !important;
}

.playVideoButtonIcon {
  width: 50px !important;
  height: 50px !important;
  color: black;
  opacity: 0.4;
  position: absolute;
  top: 80px !important;
  left: 125px !important;
}

.shareButtonVideo {
  font-style: unset;
  color: cornflowerblue !important;
  font-weight: bold !important;
}
.thumbsupButtonVideo {
  padding: 5px !important;
  margin: 10px !important;
}
.thumbdownButtonVideo {
  padding: 5px !important;
  margin: 10px !important;
}

.videoItself {
  width: 100% !important;
  position: relative;
  object-fit: fill;
  outline: none;
  display: flex;
}
.closeButton {
  color: white !important;
  position: absolute !important;
  z-index: 20;
  right: 10px;
  top: 0px;
}

.videoImages {
  height: 15rem;
  width: 10rem;
  padding: 0px !important;
}

element.style {
  margin-left: -3px !important;
}

#imageContainers {
  text-align: center;
  height: 17.8rem;
  top: 0px;
  position: relative;
}
.prevButton {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  position: absolute !important;
  padding: 95px 10px 94px 10px !important;
  bottom: 0px;
  left: 0px !important;
  border: none;
  overflow: hidden !important;
  outline: 0;
  font-size: 0;
  line-height: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.nextButton {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  position: absolute !important;
  padding: 95px 10px 94px 10px !important;
  bottom: 0px;
  right: 0px !important;
  border: none;
  overflow: hidden !important;
  outline: 0;
  font-size: 0;
  line-height: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.nextButton > span {
  color: #fefefe;
}
.prevButton > span {
  color: #fefefe;
}
* {
  box-sizing: border-box;
}

.playButtons {
  position: absolute !important;
  /* position: absolute !important; */
  /* top: -50px;
  left: -130px; */
  left: 50% !important;
  top: 32% !important;
  transform: translate(-50%, -50%) !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.MuiSvgIcon-fontSizeLarge {
  font-size: 5rem !important;
}
.splide__pagination__page {
  width: 0px !important;
}

.BrainhubCarousel__arrowLeft span {
  transform: translate(-50%, -50%) rotate(-135deg) !important;
  margin-left: 2.45px;
}

#videoFile {
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  position: absolute;
  /* right: 0;
  bottom: 0; */
  /* display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  z-index: 12 !important; */
  width: 100vw;
  max-height: 100%;
  /* width: 100%;
  height: 100%; */
  /* object-fit: contain; */
  /* padding-top: 25px; */

  /* min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto; */
  /* z-index: -100; */
  /* background-size: cover;
  overflow: hidden; */
}

.closeButtonDiv {
  position: relative;
  top: 0;
  left: 0;
  /* height: 100px; */
  height: 41px;
}
.videoPlayer {
  overflow: hidden;
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  /* margin: 0; */
  /* margin-top: 40px; */
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
  top: 0;
  left: 0;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 11;
  background-color: black;
}
.videoWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  z-index: 12 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  /*height: 100vh; */
  /* width: 100%; */
}

.videoDivider {
  background-color: white !important;
  margin-bottom: 1rem !important;
}
.videoTitle {
  text-transform: capitalize;
}

#videoFile::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
#videoFile::-internal-media-controls-download-button {
  display: none;
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  display: none;
  position: absolute;
  z-index: 0;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  text-align: center;
}

.wrapper {
  width: 100%;
  position: relative !important;
}
#prevDivWrapper {
  position: absolute;
  left: 0px;
  height: 15rem;
  background-color: white;
  z-index: 20;
}
#nextDivWrapper {
  position: absolute;
  right: 0px;
  height: 15rem;
  background-color: white;
  z-index: 20;
}

.carousel {
  border-radius: 5px;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
  height: 0;
}

#prev,
#next {
  display: flex;
  background: white;
  border: none;
  padding: 8px;
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  position: absolute;
}

#prev {
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5) !important;
  bottom: 0px !important;
}

#next {
  right: 0;
}
.content {
  display: grid;
  grid-auto-columns: calc((100% - 4 * 6px) / 5);
  grid-gap: 6px;
  grid-auto-flow: column;
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
}

.flexContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 17.8rem;
  grid-gap: 90px;
  gap: 90px;

  overflow-x: auto;
  overscroll-behavior-x: contain;
  -ms-scroll-snap-type: x proximity;
      scroll-snap-type: x proximity;
  scroll-padding-inline-start: 2rem;
  overflow-y: hidden;
}

.flexContainer::-webkit-scrollbar {
  display: none;
}

.imageContainer {
  scroll-snap-align: start;
  display: grid;
  grid-gap: 1ex;
  gap: 1ex;
  margin: 0;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.imageContainer:last-child::after {
  position: absolute;
  width: 15px;
  height: 100%;
  right: -15px;
  inline-size: 15px;
  block-size: 100%;
  inset-block-start: 0;
  inset-block-end: -15px;
}

.videoSection {
  --gap: 2.5rem;
  display: grid;
  grid-gap: var(--gap);
  gap: var(--gap);
}

.innerImgContainer {
  display: inline-block;
  inline-size: 17.8rem;
  block-size: 17.8rem;
  border-radius: 4px;
  border: 1px solid aliceblue;
  box-shadow: 0 1px 2px 0 hsl(0 0% 0% / 20%);
  box-sizing: border-box;
  overflow: hidden;
}
.splide__arrow--prev > svg + path {
  color: black !important;
  right: 0px !important;
}
.carousel-items {
  display: block;
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.musicPlayerTitle {
  width: 200px;
  margin: 10px;
  color: aliceblue;
  min-width: 100px;
  max-width: 400px;
  padding-left: 25px;
}

#imageContainers:hover .captionText {
  animation-name: onHoverAnimation;
  height: 17.6rem;
  transition: height 0.8s;
}

#imageContainers:hover .caption {
  height: 17.6rem;
  transition: height 0.8s;
}

.caption {
  transition: height 0.15s;
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 100%;
  height: 4rem;
  padding-right: 2px;
}

.captionText {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-play-state: running;
  transition: height 0.15s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 1.3rem;
  width: 100%;
  height: 4rem;
  background-color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}

.splide__arrow--next {
  right: 0em;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 105px 0px 105px 0px !important;
  width: 35px !important;
  top: 105px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.splide__arrow--prev {
  left: 0em;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 105px 0px 105px 0px !important;
  width: 35px !important;
  top: 105px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.container {
  position: relative;
  margin-bottom: 0px;
  z-index: 1000;
}

.audioFiles {
  outline: none;
}

.hidden {
  display: none;
}

.cssanimation,
.cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}

.cardTitle {
  margin: 0 !important;
}

.modalTitleBar {
  margin: 20px 20px 0px 20px;
  font-size: 2em;
}

.gridListController {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: auto;
  flex-direction: column;
  justify-content: center;
}

/* keyframes */
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes onHoverAnimation {
  0% {
    background-color: rgba(0, 0, 0, 0.65);
  }
  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}
/* Media */
/* ======================================================================== */

@media only screen and (max-width: 825px) {
  .textFieldText {
    width: 80%;
    height: 5ch !important;
  }
}

@media only screen and (min-width: 422px) {
  /* add later */
}

@media only screen and (max-width: 420px) {
  .grid__list__tile {
    width: 80% !important;
  }
  /* .MuiGridList-root {
    justify-content: center !important;
  } */
}


